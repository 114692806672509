import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";

import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import _, { map } from "lodash";

//Import Image
import images from "assets/images";
import companies from "assets/images/companies";

import {
  getProjects,
  updateProject,
  deleteProject,
} from "../../store/projects/actions";
import { getFirebaseBackend } from "helpers/firebase_helper";

const ProjectsList = props => {
  const { getRealtimeDb } = getFirebaseBackend();
  const [projects, setProjects] = React.useState([]);
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [project, setProject] = React.useState({});
  const [isEdit, setIsEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentProject, setCurrentProject] = React.useState({});

  useEffect(() => {
    setLoading(true);
    getRealtimeDb("web").on("value", snapshot => {
      setProjects(_.toArray(snapshot.val()));
      setLoading(false);
    });
  }, []);

  const handleProjectClicks = arg => {
    setSelectedProject(arg);
    toggle();
  };

  //Delete project list
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const onClickDelete = project => {
    setCurrentProject(project);
    setDeleteModal(true);
  };

  const handleDeleteProject = () => {
    getRealtimeDb("web").child(currentProject.timestamp).remove();
    setCurrentProject({});
    setDeleteModal(false);
  };

  const handleProjectClick = arg => {
    const clickedProject = arg;
    setProject(clickedProject);
    setIsEdit(true);
    toggle();
  };

  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProject}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Projekty" breadcrumbItem="Ponuky z webu" />
          {loading && (
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                    Načítavam
                  </Link>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "100px" }}>
                          #
                        </th>
                        <th scope="col">Názov</th>
                        <th scope="col">Dátum</th>
                        <th scope="col">Status</th>
                        <th scope="col">Používatelia</th>
                        <th scope="col">Akcia</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(projects, (project, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <h5 className="text-truncate font-size-14">
                                <Link
                                  to={`/project-detail/${project.timestamp}`}
                                  className="text-dark"
                                >
                                  {project.name}
                                </Link>
                              </h5>
                              <p className="text-muted mb-0">
                                {project.service}
                              </p>
                            </td>
                            <td>
                              {moment(project.timestamp).format("DD.MM.YYYY")}
                            </td>
                            <td>
                              <Badge
                                color={project.color}
                                className={"bg-" + project.color}
                              >
                                NOVÝ
                              </Badge>
                            </td>
                            <td>
                              <div className="avatar-group">
                                {map(project.team, (member, index) =>
                                  !member.img || member.img !== "Null" ? (
                                    <div
                                      className="avatar-group-item"
                                      key={index}
                                    >
                                      <Link
                                        to="#"
                                        className="d-inline-block"
                                        id={"member" + member.id}
                                      >
                                        <img
                                          src={images[member.img]}
                                          className="rounded-circle avatar-xs"
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                  ) : (
                                    <Link
                                      to="#"
                                      className="d-inline-block"
                                      id={"member" + member.id}
                                      key={"_team_" + index}
                                    >
                                      <div className="avatar-xs">
                                        <span
                                          className={
                                            "avatar-title rounded-circle bg-" +
                                            member.color +
                                            " text-white font-size-16"
                                          }
                                        >
                                          {member.name}
                                        </span>
                                      </div>
                                    </Link>
                                  )
                                )}
                              </div>
                            </td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  href="#"
                                  className="card-drop"
                                  tag="a"
                                >
                                  <i className="mdi mdi-dots-horizontal font-size-18" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem
                                    href="#"
                                    onClick={() => handleProjectClick(project)}
                                  >
                                    <i className="mdi mdi-plus font-size-16 text-success me-1" />{" "}
                                    Vytvoriť nový projekt
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#"
                                    onClick={() => onClickDelete(project)}
                                  >
                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                                    Vymazať
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <Modal isOpen={false} className={props.className}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Project" : "Add Project"}
                    </ModalHeader>
                    <ModalBody>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          id: (project && project.id) || "",
                          img: (project && project.img) || "",
                          name: (project && project.name) || "",
                          description: (project && project.description) || "",
                          status: (project && project.status) || "",
                          color: (project && project.color) || "",
                          dueDate: (project && project.dueDate) || "",
                          team: (project && project.team) || "",
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required("Please Enter Your Name"),
                          description: Yup.string().required(
                            "Please Enter Your Description"
                          ),
                          status: Yup.string().required(
                            "Please Enter Your Status"
                          ),
                          color: Yup.string().required(
                            "Please Enter Your Color"
                          ),
                        })}
                        onSubmit={values => {
                          if (isEdit) {
                            const updateProject = {
                              id: project.id,
                              img: values.img,
                              name: values.name,
                              description: values.description,
                              status: values.status,
                              color: values.color,
                              dueDate: values.dueDate,
                              team: values.team,
                            };

                            // update user
                            onUpdateProject(updateProject);
                          } else {
                            const newProject = {
                              id: Math.floor(Math.random() * (30 - 20)) + 20,
                              name: values["name"],
                              description: values["description"],
                              status: values["status"],
                              color: values["color"],
                              dueDate: values["dueDate"],
                              team: values["team"],
                            };
                            // save new user
                            onAddNewProject(newProject);
                          }

                          setState({ selectedProject: null });
                          toggle();
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form>
                            <Row>
                              <Col className="col-12">
                                <div className="mb-3">
                                  <Field name="img" type="hidden" />
                                </div>
                                <div className="mb-3">
                                  <Field name="team" type="hidden" />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Name</Label>
                                  <Field
                                    name="name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.name && touched.name
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Description
                                  </Label>
                                  <Field
                                    name="description"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.description && touched.description
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Status</Label>
                                  <Field
                                    name="status"
                                    as="select"
                                    className={
                                      "form-control" +
                                      (errors.status && touched.status
                                        ? " is-invalid"
                                        : "")
                                    }
                                  >
                                    <option>Completed</option>
                                    <option>Pending</option>
                                    <option>Delay</option>
                                  </Field>
                                  <ErrorMessage
                                    name="status"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Color</Label>
                                  <Field
                                    name="color"
                                    as="select"
                                    className={
                                      "form-control" +
                                      (errors.color && touched.color
                                        ? " is-invalid"
                                        : "")
                                    }
                                  >
                                    <option>success</option>
                                    <option>warning</option>
                                    <option>danger</option>
                                  </Field>
                                  <ErrorMessage
                                    name="color"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Due Date</Label>
                                  <Field
                                    name="dueDate"
                                    type="date"
                                    className={
                                      "form-control" +
                                      (errors.dueDate && touched.dueDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="dueDate"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                  >
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ProjectsList.propTypes = {
  projects: PropTypes.array,
  className: PropTypes.any,
  onGetProjects: PropTypes.func,
  onDeleteProject: PropTypes.func,
  onUpdateProject: PropTypes.func,
  onAddNewProject: PropTypes.func,
};

const mapStateToProps = ({ projects }) => ({
  projects: projects.projects,
});

const mapDispatchToProps = dispatch => ({
  onGetProjects: () => dispatch(getProjects()),
  onUpdateProject: project => dispatch(updateProject(project)),
  onDeleteProject: project => dispatch(deleteProject(project)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectsList));
