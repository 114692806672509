import { size } from "lodash";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Link } from "react-router-dom";

import { Badge, Button, Card, CardBody } from "reactstrap";

import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal";

const LatestTranaction = () => {
  const [viewModal, setViewModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const EcommerceOrderColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      formatter: (cellContent, user) => <>{order.id}</>,
    },

    {
      dataField: "actionName",
      text: "Akcia",
      sort: true,
    },
    {
      dataField: "timestamp",
      text: "Dátum/Čas",
      sort: true,
    },
    {
      dataField: "user",
      text: "Používateľ",
      sort: true,
    },

    {
      dataField: "view",
      isDummyField: true,
      text: "Detaily",
      sort: true,
      formatter: () => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={this.toggleViewModal}
        >
          View Details
        </Button>
      ),
    },
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const toggleViewModal = () => {
    setViewModal(!viewModal);
  };

  const pageOptions = {
    sizePerPage: 6,
    totalSize: size(orders),
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={viewModal} toggle={toggleViewModal} />
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Posledné akcie</div>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={EcommerceOrderColumns}
            data={orders}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={EcommerceOrderColumns}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <div className="table-responsive">
                      <BootstrapTable
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                        responsive
                        defaultSorted={defaultSorted}
                        bordered={false}
                        striped={false}
                        classes={"table align-middle table-nowrap table-check"}
                        headerWrapperClasses={"table-light"}
                      />
                    </div>
                    <div className="pagination pagination-rounded justify-content-end">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default connect()(withRouter(LatestTranaction));
