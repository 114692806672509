import Breadcrumbs from "components/Common/Breadcrumb";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { getProjectDetail } from "store/actions";
import AttachedFiles from "./attachedFiles";
import Comments from "./comments";
import ContactDetails from "./contactDetails";
import ProjectDetail from "./projectDetail";
import TeamMembers from "./teamMembers";

const ProjectsOverview = props => {
  const { projects } = useSelector(state => state.projects);
  const [project, setProject] = useState(projects[props.match.params.id] || {});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjectDetail(props.match.params.id));
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projekty" breadcrumbItem="Detaily projektu" />

          {!isEmpty(project) ? (
            <>
              <Row>
                <Col lg="8">
                  <ProjectDetail project={project} />
                  <Comments project={project} />
                </Col>

                <Col lg="4">
                  <ContactDetails project={project} />
                  <TeamMembers project={project} />
                  <AttachedFiles project={project} />
                </Col>
              </Row>
            </>
          ) : (
            <p>{"Nenašli sme projekt"}</p>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default connect()(withRouter(ProjectsOverview));
