import { doc, getDoc, getFirestore } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const TeamMember = ({ member }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentMember, setCurrentMember] = useState({
    firstName: "",
    lastName: "",
    profileImage: "",
    personalDetail: "",
    uid: "",
  });

  const getUser = async () => {
    await getDoc(doc(getFirestore(), "users", member.uid)).then(data =>
      setCurrentMember(data.data())
    );
    setIsLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <tr key={"_member_"}>
      {isLoading ? (
        <Skeleton width={"90%"} count={1} />
      ) : (
        <>
          <td style={{ width: "50px" }}>
            <img
              src={currentMember?.profileImage || ""}
              className="rounded-circle avatar-xs"
              alt=""
            />
          </td>
          <td>
            <h5 className="font-size-14 m-0">
              <Link
                to={`/profile/${currentMember?.uid || ""}`}
                className="text-dark"
              >
                {currentMember?.firstName || ""} {currentMember?.lastName || ""}
              </Link>
            </h5>
          </td>
          <td>
            <div>
              <span className="badge bg-primary bg-soft text-primary font-size-11 me-1">
                {currentMember?.personalDetail || ""}
              </span>
            </div>
          </td>
        </>
      )}
    </tr>
  );
};
