import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";

import DailyCalendar from "./DailyCalendar";
import LatestTranaction from "./LatestTranaction";
import WelcomeComp from "./WelcomeComp";

import MyProjects from "./MyProjects";
import { getProjects, getUsers } from "store/actions";

const Dashboard = props => {
  const { uid, roleLevel } = useSelector(state => state.auth.userData);

  const dispatch = useDispatch();
  const projects = useSelector(state => state.projects.projects);
  useEffect(() => {
    dispatch(getProjects(uid));
    dispatch(getUsers());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <WelcomeComp />
            </Col>
            {/* <Col xl="6">
              <DailyCalendar />
            </Col> */}
            <Col xl="12">
              {/* <Reports /> */}
              <MyProjects projects={projects} />
            </Col>
          </Row>

          {/* <Row>
            <Col xl="4">
              <SocialSource />
            </Col>
            <Col xl="4">
              <ActivityComp />
            </Col>
            <Col xl="4">
              <TopCities />
            </Col>
          </Row> */}
          {Number(roleLevel) === 1 && (
            <Row>
              <Col lg="12">
                <LatestTranaction />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default connect()(Dashboard);
