import React, { createRef, useEffect } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { Link, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserData } from "store/selectors";

const SidebarContent = () => {
  const refDiv = createRef();
  const currentUser = useSelector(selectUserData);
  useEffect(() => {
    initMenu();
  }, []);

  const initMenu = () => {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  };

  const scrollElement = item => {
    setTimeout(() => {
      if (refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (refDiv.current)
              refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  const activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  };
  const isProd = true || process.env.NODE_ENV === "production";

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={refDiv}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Menu</li>
            <li>
              <Link to="/#">
                <i className="bx bx-home-circle" />
                {/* <span className="badge rounded-pill bg-info float-end">
                    04
                  </span> */}
                <span>Nástenka</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-briefcase-alt-2" />
                <span>Projekty</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {Number(currentUser.roleLevel) === 1 && (
                  <li>
                    <Link to="/all-projects">Všetky Projekty</Link>
                  </li>
                )}
                <li>
                  <Link to="/my-projects">Moje Projekty</Link>
                </li>
                {/* <li>
                    <Link to="/pages-pricing">
                      {Cenová ponuka
                    </Link>
                  </li> */}
                {Number(currentUser.roleLevel) === 1 && (
                  <li>
                    <Link to="/projects-list">Ponuky z webu</Link>
                  </li>
                )}
                {/*
                  jednotlivý projekt
                  <li>
                    <Link to="/projects-overview">
                      {Project Overview
                    </Link>
                  </li> */}
                {Number(currentUser.roleLevel) <= 2 && (
                  <li>
                    <Link to="/projects-create">Pridať nový</Link>
                  </li>
                )}
              </ul>
            </li>
            {!isProd && (
              <li>
                <Link to="/calendar" className="">
                  <i className="bx bx-calendar" />
                  <span>Kalendár</span>
                </Link>
              </li>
            )}

            {!isProd && (
              <li>
                <Link to="/chat" className="">
                  <i className="bx bx-chat" />
                  <span>Chat</span>
                </Link>
              </li>
            )}
            {!isProd && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-task" />
                  <span>Úlohy</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/tasks-list">Task List</Link>
                  </li>
                  <li>
                    <Link to="/tasks-kanban">Kanban Board</Link>
                  </li>
                  <li>
                    <Link to="/tasks-create">Create Task</Link>
                  </li>
                </ul>
              </li>
            )}
            {Number(currentUser.roleLevel) === 1 && !isProd && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-user-detail" />
                  <span>Kontakty</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/contacts-grid">User Grid</Link>
                  </li>
                  <li>
                    <Link to="/contacts-list">User List</Link>
                  </li>
                  <li>
                    <Link to="/contacts-profile">Profile</Link>
                  </li>
                </ul>
              </li>
            )}
            {!isProd && (
              <li>
                <Link to="/apps-filemanager" className="">
                  <i className="bx bx-file" />

                  <span>Súbory</span>
                </Link>
              </li>
            )}

            {Number(currentUser.roleLevel) === 1 && (
              <li>
                <Link to="/ecommerce-customers">
                  <i className="bx bx-store" />
                  <span>Používatelia</span>
                </Link>
              </li>
            )}

            {Number(currentUser.roleLevel) === 1 && !isProd && (
              <li>
                <Link to="/invoices-list">
                  <i className="bx bx-receipt" />
                  <span>Faktúry</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/invoices-list">Invoice List</Link>
                  </li>
                  <li>
                    <Link to="/invoices-detail">Invoice Detail</Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default withRouter(SidebarContent);
