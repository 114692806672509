import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getFirebaseBackend } from "helpers/firebase_helper";
import { doc, getFirestore, setDoc } from "firebase/firestore";

const ProjectsCreate = () => {
  const { getCollectionFromFirestore } = getFirebaseBackend();
  const [users, setUsers] = useState([]);
  const [client, setClient] = useState([]);
  const [team, setTeam] = useState([]);

  const teamOptions = users
    .filter(item => Number(item.roleLevel) <= 2)
    .reduce(
      (acc, item) => [
        ...acc,
        { value: item, label: item.firstName + " " + item.lastName },
      ],
      []
    );
  const clientOptions = users
    .filter(item => Number(item.roleLevel) === 3)
    .reduce(
      (acc, item) => [
        ...acc,
        { value: item, label: item.firstName + " " + item.lastName },
      ],
      []
    );

  useEffect(() => {
    getCollectionFromFirestore("users").then(data => setUsers(data));
  }, []);

  const [firebaseFiles, setFirebaseFiles] = useState([]);

  const handleAcceptedFiles = files => {
    files.map(item => {
      const storage = getStorage();
      const storageRef = ref(storage, item.name);
      const uploadTask = uploadBytesResumable(storageRef, item);
      uploadTask.on(
        "state_changed",
        _ => {},
        _ => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
            const fileId = Date.now();
            setFirebaseFiles([
              ...firebaseFiles,
              {
                fileName: item.name,
                url: downloadURL,
                preview: URL.createObjectURL(item),
                formattedSize: formatBytes(item.size),
                name: item.name,
                type: item.type,
                id: fileId,
              },
            ]);
          });
        }
      );
    });
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projekty" breadcrumbItem="Pridať nový" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Pridat Nový Projekt</CardTitle>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      name: "",
                      projectdesc: "",
                      dueDate: "",
                      fromDate: "",
                      projectbudget: "",
                    }}
                    onSubmit={async (values, { resetForm }) => {
                      const projectId = Date.now();
                      await setDoc(
                        doc(getFirestore(), "projects", `${projectId}`),
                        {
                          ...values,
                          descHTML: values.projectdesc.replaceAll(
                            /\n\r?/g,
                            "<br />"
                          ),
                          id: projectId,
                          team,
                          client,
                          files:
                            firebaseFiles.length > 0
                              ? firebaseFiles
                              : [
                                  {
                                    name: "becode.png",
                                    url: "https://firebasestorage.googleapis.com/v0/b/becodesk.appspot.com/o/logo.png?alt=media&token=17791eab-2183-41cf-80e9-dc4436b0d58f",
                                    size: "2.35 kB",
                                    main: true,
                                  },
                                ],
                        }
                      );
                      setTeam([]);
                      setClient([]);
                      setFirebaseFiles([]);
                      resetForm();
                    }}
                  >
                    {({ errors, touched }) => (
                      <React.Fragment>
                        <Form>
                          <Row className="mb-4">
                            <Label
                              htmlFor="name"
                              className="col-form-label col-lg-2"
                            >
                              Názov
                            </Label>
                            <Col lg="10">
                              <Field
                                name="name"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.name && touched.name
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Label
                              htmlFor="dueDate"
                              className="col-form-label col-lg-2"
                            >
                              Začiatok/Deadline
                            </Label>
                            <Col lg="10">
                              <Row>
                                <Col lg="6">
                                  <Field
                                    name="fromDate"
                                    type="date"
                                    className={
                                      "form-control" +
                                      (errors.dueDate && touched.dueDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="fromDate"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </Col>

                                <Col lg="6">
                                  <Field
                                    name="dueDate"
                                    type="date"
                                    className={
                                      "form-control" +
                                      (errors.fromDate && touched.fromDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="dueDate"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Label
                              htmlFor="projectbudget"
                              className="col-form-label col-lg-2"
                            >
                              Budget
                            </Label>
                            <Col lg="10">
                              <Field
                                name="projectbudget"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.projectbudget && touched.projectbudget
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="projectbudget"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Label
                              htmlFor="projectdesc"
                              className="col-form-label col-lg-2"
                            >
                              Popis projektu
                            </Label>
                            <Col lg="10">
                              <Field
                                component="textarea"
                                rows={10}
                                name="projectdesc"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.projectdesc && touched.projectdesc
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="projectdesc"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Label
                              htmlFor="client"
                              className="col-form-label col-lg-2"
                            >
                              Klient
                            </Label>
                            <Col lg="10">
                              <Select
                                isSearchable
                                name="client"
                                options={clientOptions}
                                isMulti
                                placeholder="Vybrať ..."
                                value={client}
                                onChange={selected => {
                                  setClient(
                                    selected.reduce(
                                      (acc, item) => [
                                        ...acc,
                                        {
                                          uid: item.value.uid,
                                          label: item.label,
                                        },
                                      ],
                                      []
                                    )
                                  );
                                }}
                              />
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Label
                              htmlFor="team"
                              className="col-form-label col-lg-2"
                            >
                              Team
                            </Label>
                            <Col lg="10">
                              <Select
                                isSearchable
                                name="team"
                                options={teamOptions}
                                placeholder="Vybrať ..."
                                isMulti
                                value={team}
                                onChange={selected => {
                                  setTeam(
                                    selected.reduce(
                                      (acc, item) => [
                                        ...acc,
                                        {
                                          uid: item.value.uid,
                                          label: item.label,
                                        },
                                      ],
                                      []
                                    )
                                  );
                                }}
                              />
                            </Col>
                          </Row>

                          <FormGroup className="mb-4" row>
                            <Label className="col-form-label col-lg-2">
                              Súbory
                            </Label>
                            <Col lg="10">
                              <Dropzone
                                onDrop={acceptedFiles =>
                                  handleAcceptedFiles(acceptedFiles)
                                }
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="dz-message needsclick">
                                        <div className="mb-3">
                                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h4>
                                          Presuňte súbory sem alebo ich nahrajte
                                          kliknutím.
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {firebaseFiles.map((f, i) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={i + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-4">
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={f.name}
                                              src={f.preview}
                                            />
                                          </Col>
                                          <Col className="col-6">
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {f.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>{f.formattedSize}</strong>
                                            </p>
                                          </Col>
                                          <Col className="col-1">
                                            <p
                                              style={{
                                                fontWeight: f.main && "bold",
                                              }}
                                              onClick={() => {
                                                const newFiles = firebaseFiles
                                                  .filter(
                                                    file => file.name !== f.name
                                                  )
                                                  .map(i => {
                                                    return {
                                                      ...i,
                                                      main: false,
                                                    };
                                                  });
                                                setFirebaseFiles([
                                                  { ...f, main: true },
                                                  ...newFiles,
                                                ]);
                                              }}
                                              className="mb-0"
                                            >
                                              MAIN
                                            </p>
                                          </Col>
                                          <Col className="col-1">
                                            <p className="mb-0">x</p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  );
                                })}
                              </div>
                            </Col>
                          </FormGroup>
                          <Row className="justify-content-end">
                            <Col lg="10">
                              <Button type="submit" color="primary">
                                Vytvoriť projekt
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </React.Fragment>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default connect()(withRouter(ProjectsCreate));
