import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import * as moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { getFirebaseBackend } from "helpers/firebase_helper";
import { size } from "lodash";
import { getUsers } from "store/actions";

const EcommerceCustomers = () => {
  const { users } = useSelector(state => state.contacts);
  const dispatch = useDispatch();
  const node = createRef();

  const [customer, setCustomer] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const {
    getCollectionFromFirestore,
    addNewUserToFirestore,
    updateUserInFirestore,
  } = getFirebaseBackend();

  const initialValues = {
    firstName: customer?.firstName || "",
    lastName: customer?.lastName || "",
    phone: customer?.phone || "",
    email: customer?.email || "",
    location: customer?.location || "",
    joinedAt: customer?.joinedAt || Date.now(),
    personalDetail: customer?.personalDetail || "",
    profileImage:
      customer?.profileImage ||
      "https://firebasestorage.googleapis.com/v0/b/becodesk.appspot.com/o/logo.png?alt=media&token=17791eab-2183-41cf-80e9-dc4436b0d58f",
    role: customer?.role || "Klient",
  };

  function chunk(arr, len) {
    var chunks = [],
      i = 0,
      n = arr.length;

    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }

    return chunks;
  }

  const inputs = [
    {
      name: "firstName",
      label: "Meno",
    },
    {
      name: "lastName",
      label: "Priezvisko",
    },
    {
      name: "phone",
      label: "Telefón",
    },
    {
      name: "email",
      label: "E-mail",
    },
    {
      name: "location",
      label: "Poloha",
    },
    {
      name: "joinedAt",
      label: "Pridaný",
    },
    {
      name: "personalDetail",
      label: "Detaily",
    },
    {
      name: "profileImage",
      label: "Profilový obrázok",
    },
    {
      name: "role",
      label: "Rola",
      isSelect: true,
    },
  ];

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const handleEditCustomer = arg => {
    setCustomer(arg);
    setIsEdit(true);
    toggle();
  };

  const handleNewCustomer = () => {
    setCustomer({});
    setIsEdit(false);
    toggle();
  };

  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = cstmr => {
    setCustomer(cstmr);
    setDeleteModal(true);
  };

  const handleDeleteCustomer = () => {
    //delete here
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const pageOptions = {
    sizePerPage: 10,
    totalSize: size(Object.values(users)),
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "joinedAt",
      order: "desc",
    },
  ];

  const { SearchBar } = Search;

  const EcommerceCustomerColumns = [
    {
      dataField: "firstName",
      text: "Meno a Priezvisko",
      sort: true,
      formatter: (cellContent, user) => {
        return (
          <>
            {Number(user.roleLevel) === 2 ? (
              <i className="bx bx-user text-primary" />
            ) : null}{" "}
            {user.firstName + " " + user.lastName}
          </>
        );
      },
    },
    {
      text: "Telefón",
      dataField: "phone",
      sort: true,
    },
    {
      text: "Email",
      dataField: "email",
      sort: true,
    },
    {
      text: "Detaily",
      dataField: "email",
      sort: true,
      formatter: (cellContent, user) => {
        return <span className="badge bg-warning">{user.personalDetail}</span>;
      },
    },
    {
      dataField: "location",
      text: "Poloha",
      sort: true,
    },

    {
      dataField: "joinedAt",
      text: "Pridaný",
      sort: true,
      formatter: (cellContent, row) => handleValidDate(row.joinedAt),
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Akcia",
      formatter: (cellContent, customer) => (
        <UncontrolledDropdown>
          <DropdownToggle href="#" className="card-drop" tag="a">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem
              href={`/profile/${customer.uid}`}
              onClick={() => handleEditCustomer(customer)}
            >
              <i className="mdi mdi-account font-size-16 text-warning me-1" />{" "}
              Zobraziť
            </DropdownItem>
            <DropdownItem href="#" onClick={() => handleEditCustomer(customer)}>
              <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
              Upraviť
            </DropdownItem>
            <DropdownItem href="#" onClick={() => onClickDelete(customer)}>
              <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
              Vymazať
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="BeCode" breadcrumbItem="Používatelia" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="uid"
                    columns={EcommerceCustomerColumns}
                    data={Object.values(users)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="uid"
                        columns={EcommerceCustomerColumns}
                        data={Object.values(users)}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="warning"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleNewCustomer}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Nový Používateľ
                                  </Button>
                                </div>
                              </Col>
                            </Row>

                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                ref={node}
                              />

                              <Modal size="lg" isOpen={modal}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Upraviť použivateľa"
                                    : "Nový použivateľ"}
                                </ModalHeader>
                                <ModalBody>
                                  <Formik
                                    enableReinitialize={true}
                                    initialValues={initialValues}
                                    onSubmit={values => {
                                      if (!isEdit) {
                                        addNewUserToFirestore({
                                          ...values,
                                          roleLevel:
                                            values.role === "Klient"
                                              ? 3
                                              : values.role === "Zamestnanec"
                                              ? 2
                                              : 1,
                                        });
                                      }
                                      if (isEdit) {
                                        updateUserInFirestore({
                                          ...values,
                                          uid: customer.uid,
                                        });
                                      }
                                      toggle();
                                      setCustomer(null);
                                      toggle();
                                    }}
                                  >
                                    {({ errors, touched }) => (
                                      <Form>
                                        <Row>
                                          <Col className="col-6">
                                            {chunk(inputs, 5)[0].map(
                                              (item, index) => (
                                                <div
                                                  key={index}
                                                  className="mb-3"
                                                >
                                                  <Label className="form-label">
                                                    {item.label}
                                                  </Label>
                                                  <Field
                                                    name={item.name}
                                                    type="text"
                                                    className={
                                                      "form-control" +
                                                      (errors[item.name] &&
                                                      touched[item.name]
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                  />
                                                  <ErrorMessage
                                                    name={item.name}
                                                    component="div"
                                                    className="invalid-feedback"
                                                  />
                                                </div>
                                              )
                                            )}
                                          </Col>
                                          <Col className="col-6">
                                            {chunk(inputs, 5)[1].map(
                                              (item, index) => (
                                                <div
                                                  key={index}
                                                  className="mb-3"
                                                >
                                                  <Label className="form-label">
                                                    {item.label}
                                                  </Label>
                                                  {item.isSelect ? (
                                                    <Field
                                                      as="select"
                                                      name={item.name}
                                                      type="text"
                                                      className={
                                                        "form-control" +
                                                        (errors[item.name] &&
                                                        touched[item.name]
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    >
                                                      <option>Klient</option>
                                                      <option>
                                                        Zamestnanec
                                                      </option>
                                                    </Field>
                                                  ) : (
                                                    <Field
                                                      name={item.name}
                                                      type="text"
                                                      className={
                                                        "form-control" +
                                                        (errors[item.name] &&
                                                        touched[item.name]
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                  )}
                                                  <ErrorMessage
                                                    name={item.name}
                                                    component="div"
                                                    className="invalid-feedback"
                                                  />
                                                </div>
                                              )
                                            )}
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button
                                                type="submit"
                                                className="btn btn-warning save-customer"
                                              >
                                                Uložiť
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Form>
                                    )}
                                  </Formik>
                                </ModalBody>
                              </Modal>
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default connect()(EcommerceCustomers);
