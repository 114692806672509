import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import Cards

import { getProjects } from "store/actions";
import CardProject from "./card-project";

const ProjectsGrid = ({ location }) => {
  const myOnly = location.pathname === "/my-projects";
  const [page, setPage] = useState(1);
  const { uid } = useSelector(state => state.auth.userData);
  const projects = useSelector(state => state.projects);
  const [totalPage] = useState((projects || []).length);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjects(uid));
  }, []);

  const handlePageClick = p => {
    setPage(p);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Projekty"
            breadcrumbItem={myOnly ? "Moje Projekty" : "Všetky Projekty"}
          />

          <Row>
            {/* Import Cards */}
            <CardProject
              projects={projects.projects || []}
              key={"cardproject"}
            />
          </Row>
          {projects.length > 10 && (
            <Row>
              <Col lg="12">
                <Pagination className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                  <PaginationItem disabled={page === 1}>
                    <PaginationLink
                      previous
                      href="#"
                      onClick={() => handlePageClick(page - 1)}
                    />
                  </PaginationItem>
                  {map(Array(totalPage), (item, i) => (
                    <PaginationItem active={i + 1 === page} key={"_k" + i}>
                      <PaginationLink
                        onClick={() => handlePageClick(i + 1)}
                        href="#"
                      >
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={page === totalPage}>
                    <PaginationLink
                      next
                      href="#"
                      onClick={() => handlePageClick(page + 1)}
                    />
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default connect()(withRouter(ProjectsGrid));
