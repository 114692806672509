import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      fireBaseBackend.loginUser,
      user.email,
      user.password
    );

    const userData = yield call(fireBaseBackend.getUserFromFirestore, "users");

    yield put(loginSuccess(response, userData));
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    const response = yield call(fireBaseBackend.logout);
    yield put(logoutUserSuccess(response));

    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
