import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  isLoggedIn: false,
  user: null,
  userData: null,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        user: action.payload.user,
        userData: action.payload.userData,
      };

    case LOGOUT_USER:
      return { ...state, loading: true };

    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        user: null,
        userData: null,
      };

    case API_ERROR:
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
};

export default login;
