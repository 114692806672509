import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { clearProjects, logoutUser } from "../../store/actions";

const Logout = ({ logoutUser, history }) => {
  useEffect(() => {
    clearProjects();
    logoutUser(history);
  });

  return <React.Fragment></React.Fragment>;
};

export default withRouter(connect(null, { logoutUser, clearProjects })(Logout));
