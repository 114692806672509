import { map } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, Col } from "reactstrap";

class CardProject extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        {map(this.props.projects, (project, dkey) => (
          <Col xl="4" sm="6" key={"__projectcd_" + dkey}>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="avatar-md me-4">
                    <span className="avatar-title rounded-circle bg-light overflow-hidden">
                      <img
                        src={project.files.find(i => i.main)?.url || ""}
                        alt=""
                        height="100"
                      />
                    </span>
                  </div>

                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="text-truncate font-size-15">
                      <Link
                        to={`/project-detail/${project.id}`}
                        className="text-dark"
                      >
                        {project.name}
                      </Link>
                    </h5>

                    <div className="avatar-group flex-column ">
                      {project.client.map((team, secondkey) => (
                        <React.Fragment key={"_teamaa__" + secondkey}>
                          <span
                            className="badge bg-warning w-50"
                            style={{ marginTop: "3px", marginBottom: "3px" }}
                          >
                            {team.label}
                          </span>
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="avatar-group flex-column ">
                      {project.team.map((team, secondkey) => (
                        <React.Fragment key={"_teamaa__" + secondkey}>
                          <span
                            className="badge bg-info w-50"
                            style={{ marginTop: "3px", marginBottom: "3px" }}
                          >
                            {team.label}
                          </span>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </CardBody>
              <div className="px-4 py-3 border-top">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item me-3">
                    <Badge
                      color={project.color}
                      className={"bg-" + project.color}
                    >
                      {project.status}
                    </Badge>
                  </li>{" "}
                  <li className="list-inline-item me-3" id="dueDate">
                    <i className="bx bx-calendar me-1" />{" "}
                    {moment(project.fromDate).isValid()
                      ? moment(project.fromDate).format("DD.MM.YYYY")
                      : "Neurčený"}
                  </li>{" "}
                  <li className="list-inline-item me-3" id="comments">
                    <i className="bx bx-calendar me-1" />{" "}
                    {moment(project.dueDate).isValid()
                      ? moment(project.dueDate).format("DD.MM.YYYY")
                      : "Neurčený"}
                  </li>
                </ul>
              </div>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

CardProject.propTypes = {
  projects: PropTypes.array,
};

export default CardProject;
