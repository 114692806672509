import images from "assets/images";
import { arrayUnion, doc, getFirestore, updateDoc } from "firebase/firestore";
import { Formik } from "formik";
import { map } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  Input,
  Media,
} from "reactstrap";
import { addComment, removeComment } from "store/actions";

const Comments = ({ project }) => {
  const [currentComments, setCurrentComments] = useState(
    project.comments || []
  );
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.userData);
  const [comment, setComment] = useState("");

  const saveComment = values => {
    const data = {
      comment,
      timestamp: Date.now(),
      fullName: currentUser.firstName + " " + currentUser.lastName,
      profileImage: currentUser.profileImage,
    };
    setCurrentComments([...currentComments, data]);
    dispatch(addComment(project, data));
    setComment("");
  };
  const removeCurrentComment = comment => {
    dispatch(removeComment(project, comment));
    setCurrentComments(
      currentComments.filter(item => item.timestamp !== comment.timestamp)
    );
  };
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Komentáre</CardTitle>
        {map(currentComments, (comment, index) => (
          <Media className="mb-4" key={index}>
            <div className="me-3">
              {comment.profileImage ? (
                <img
                  className="media-object rounded-circle avatar-xs"
                  alt=""
                  src={comment.profileImage}
                />
              ) : (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-16">
                    {comment.name.charAt(0)}
                  </span>
                </div>
              )}
            </div>
            <Media body>
              <h5 className="font-size-13 mb-1">
                {comment.fullName} -{" "}
                {moment(Number(comment.timestamp)).format("DD.MM.YYYY HH:mm")}
              </h5>
              <div className="d-flex justify-content-between">
                <p style={{ width: "95%" }} className="text-muted mb-1">
                  {comment.comment}
                </p>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => removeCurrentComment(comment)}
                >
                  x
                </span>
              </div>
            </Media>
          </Media>
        ))}
        {/* <div className="text-center mt-4 pt-2">
            <Link to="#" className="btn btn-primary btn-sm">
              View more
            </Link>
          </div> */}
        <Formik
          initialValues={{
            comment: "",
          }}
          onSubmit={saveComment}
        >
          {({ handleSubmit, handleChange }) => (
            <Form>
              <Input
                onChange={e => setComment(e.target.value)}
                className="form-control mb-3"
                name="comment"
                value={comment}
                placeholder="Správa..."
              />
              <div className="text-center">
                <Button
                  color="primary"
                  onClick={handleSubmit}
                  className="text-white"
                >
                  Odoslať
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default Comments;
