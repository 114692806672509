import {
  GET_PROJECTS,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  ADD_NEW_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  ADD_COMMENT,
  ADD_COMMENT_SUCCESS,
  REMOVE_COMMENT,
  REMOVE_COMMENT_SUCCESS,
  UPDATE_FILES,
  SAVE_TEAM_MEMBERS,
  SAVE_TEAM_MEMBERS_SUCCESS,
  CLEAR_PROJECTS,
} from "./actionTypes";

export const getProjects = uid => ({
  type: GET_PROJECTS,
  uid,
});

export const getProjectsSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: { projects },
});

export const addComment = (project, comment) => ({
  type: ADD_COMMENT,
  payload: { project, comment },
});

export const removeComment = (project, comment) => ({
  type: REMOVE_COMMENT,
  payload: { project, comment },
});

export const removeCommentSuccess = (comment, project) => ({
  type: REMOVE_COMMENT_SUCCESS,
  payload: { comment, project },
});

export const addCommentSuccess = (comment, project) => ({
  type: ADD_COMMENT_SUCCESS,
  payload: { comment, project },
});

export const saveTeamMembers = (project, teamMembers) => ({
  type: SAVE_TEAM_MEMBERS,
  payload: { teamMembers, project },
});

export const saveTeamMembersSuccess = (project, teamMembers) => ({
  type: SAVE_TEAM_MEMBERS_SUCCESS,
  payload: { teamMembers, project },
});

export const getProjectsFail = error => ({
  type: GET_PROJECTS_FAIL,
  payload: error,
});

export const getProjectDetail = projectId => ({
  type: GET_PROJECT_DETAIL,
  projectId,
});

export const getProjectDetailSuccess = projectDetails => ({
  type: GET_PROJECT_DETAIL_SUCCESS,
  payload: projectDetails,
});

export const getProjectDetailFail = error => ({
  type: GET_PROJECT_DETAIL_FAIL,
  payload: error,
});

export const addNewProject = project => ({
  type: ADD_NEW_PROJECT,
  payload: project,
});

export const addProjectSuccess = project => ({
  type: ADD_PROJECT_SUCCESS,
  payload: project,
});

export const addProjectFail = error => ({
  type: ADD_PROJECT_FAIL,
  payload: error,
});

export const updateProject = project => ({
  type: UPDATE_PROJECT,
  payload: project,
});

export const updateProjectSuccess = project => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: project,
});

export const updateProjectFail = error => ({
  type: UPDATE_PROJECT_FAIL,
  payload: error,
});

export const deleteProject = project => ({
  type: DELETE_PROJECT,
  payload: project,
});

export const deleteProjectSuccess = project => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: project,
});

export const deleteProjectFail = error => ({
  type: DELETE_PROJECT_FAIL,
  payload: error,
});

export const updateFiles = (project, files) => ({
  type: UPDATE_FILES,
  payload: { project, files },
});

export const clearProjects = () => {
  return {
    type: CLEAR_PROJECTS,
  };
};
