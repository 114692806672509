import React from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { selectUserData } from "store/selectors";
import Breadcrumb from "../../components/Common/Breadcrumb";

const UserProfile = props => {
  const currentUser = props.match.params.id
    ? useSelector(state => state.contacts.users[props.match.params.id])
    : useSelector(selectUserData);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="BeCode" breadcrumbItem="Profil" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={currentUser.profileImage || ""}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>
                          {currentUser.firstName + " " + currentUser.lastName}
                        </h5>
                        <p className="mb-1">{currentUser.email}</p>
                        <p className="mb-0">ID: {currentUser.uid}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Detaily</h4>

          <Card>
            <CardBody>
              <CardTitle className="mb-4 h4">Osobné informácie</CardTitle>

              <p className="text-muted mb-4">{currentUser.personalDetail}</p>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Meno a Priezvisko :</th>
                      <td>
                        {" "}
                        {currentUser.firstName + " " + currentUser.lastName}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Telefón :</th>
                      <td>{currentUser.phone}</td>
                    </tr>
                    <tr>
                      <th scope="row">E-mail :</th>
                      <td>{currentUser.email}</td>
                    </tr>
                    <tr>
                      <th scope="row">Poloha :</th>
                      <td>{currentUser.location}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect()(UserProfile));
