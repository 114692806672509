import { getDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import { saveTeamMembers } from "store/actions";
import { TeamMember } from "./teamMember";

const TeamMembers = ({ project }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { roleLevel } = useSelector(state => state.auth.userData);
  const dispatch = useDispatch();
  const users = useSelector(state => state.contacts.users);
  const [teamMembers, setTeamMembers] = useState(project.team || []);
  const teamOptions = Object.values(users)
    .filter(item => Number(item.roleLevel) <= 2)
    .filter(item => {
      const currentTeam = teamMembers.reduce(
        (acc, item) => [...acc, item.label],
        []
      );

      return !currentTeam.includes(item.firstName + " " + item.lastName);
    })
    .reduce(
      (acc, item) => [
        ...acc,
        {
          uid: item.uid,
          value: item,
          label: item.firstName + " " + item.lastName,
        },
      ],
      []
    );

  const saveTeam = () => {
    dispatch(saveTeamMembers(project, teamMembers));
    return;
  };
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Team</CardTitle>

        <div className="table-responsive">
          <Table className="table align-middle table-nowrap">
            <tbody>
              {teamMembers.map((member, k) => (
                <TeamMember member={member} key={k} />
              ))}
            </tbody>
          </Table>
        </div>
        {isEdit && (
          <Select
            isSearchable
            name="team"
            options={teamOptions}
            placeholder="Vybrať ..."
            isMulti
            value={teamMembers}
            onChange={selected => {
              setTeamMembers(selected);
            }}
          />
        )}
      </CardBody>
      {Number(roleLevel) === 1 && (
        <button
          onClick={() => {
            if (isEdit) {
              saveTeam();
            }
            setIsEdit(!isEdit);
          }}
          className="btn btn-primary"
        >
          {isEdit ? "Potvrdiť" : "Upraviť"}
        </button>
      )}
    </Card>
  );
};

export default TeamMembers;
