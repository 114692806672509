import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { map } from "lodash";
import Dropzone from "react-dropzone";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { arrayUnion, doc, getFirestore, updateDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { updateFiles } from "store/actions";

const AttachedFiles = ({ project }) => {
  const [currentFiles, setCurrentFiles] = useState(project.files || []);
  const dispatch = useDispatch();
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const updateTempFiles = async files => {
    setCurrentFiles([...currentFiles, ...files]);
    await updateDoc(doc(getFirestore(), "projects", `${project.id}`), {
      files: arrayUnion(...files),
    });
    dispatch(updateFiles(project, files));
  };

  const handleAcceptedFiles = files => {
    var tempFiles = [];
    files.map(item => {
      const storage = getStorage();
      const storageRef = ref(storage, item.name);
      const uploadTask = uploadBytesResumable(storageRef, item);
      uploadTask.on(
        "state_changed",
        _ => {},
        _ => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
            const fileId = Date.now();

            tempFiles.push({
              fileName: item.name,
              url: downloadURL,
              preview: URL.createObjectURL(item),
              formattedSize: formatBytes(item.size),
              name: item.name,
              type: item.type,
              id: fileId,
            });
            updateTempFiles(tempFiles);
          });
        }
      );
    });
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Súbory</CardTitle>
        <div className="table-responsive">
          <Table className="table table-nowrap align-middle table-hover mb-0">
            <tbody>
              {map(currentFiles, (file, i) => {
                return (
                  <tr key={"_file_" + i}>
                    <td style={{ width: "45px" }}>
                      <div className="avatar-sm">
                        <img
                          src={file.url}
                          className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24"
                          alt=""
                        />
                      </div>
                    </td>
                    <td>
                      <h5 className="font-size-14 mb-1">
                        <Link to="#" className="text-dark">
                          {file.fileName?.length > 20
                            ? file.fileName.slice(0, 20) + "..."
                            : file.fileName}
                        </Link>
                      </h5>
                      <small>Veľkosť : {file.formattedSize}</small>
                    </td>
                    <td>
                      <div className="text-center">
                        <a
                          href={file.url}
                          download={file.fileName}
                          className="text-dark"
                        >
                          <i className="bx bx-download h3 m-0" />
                        </a>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Dropzone
            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone mt-3">
                <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-message needsclick">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />

                    <h6>Presuňte súbory sem alebo ich nahrajte kliknutím.</h6>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      </CardBody>
    </Card>
  );
};

export default AttachedFiles;
