import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  addCommentSuccess,
  addProjectFail,
  addProjectSuccess,
  deleteProjectFail,
  deleteProjectSuccess,
  getProjectDetailFail,
  getProjectDetailSuccess,
  getProjectsFail,
  getProjectsSuccess,
  removeComment,
  removeCommentSuccess,
  saveTeamMembersSuccess,
  updateProjectFail,
  updateProjectSuccess,
} from "./actions";
import {
  ADD_NEW_PROJECT,
  DELETE_PROJECT,
  GET_PROJECTS,
  GET_PROJECT_DETAIL,
  UPDATE_PROJECT,
  ADD_COMMENT,
  REMOVE_COMMENT,
  SAVE_TEAM_MEMBERS,
} from "./actionTypes";

//Include Both Helper File with needed methods
import {
  addNewProject,
  deleteProject,
  getProjectsDetails,
  updateProject,
} from "helpers/fakebackend_helper";

import { getFirebaseBackend } from "helpers/firebase_helper";
import {
  arrayRemove,
  arrayUnion,
  doc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";

function* fetchProjects({ uid }) {
  try {
    const { getCollectionFromFirestore } = getFirebaseBackend();

    const res = yield call(getCollectionFromFirestore, "projects");

    const filteredProjects = res
      .sort((a, b) => b.id - a.id)
      .filter(
        item =>
          item.team.find(data => data.uid === uid) ||
          item.client.find(data => data.uid === uid)
      )
      .reduce((acc, curr) => {
        return { ...acc, [curr.id]: curr };
      }, {});

    yield put(getProjectsSuccess(filteredProjects));
  } catch (error) {
    yield put(getProjectsFail(error));
  }
}

function* fetchProjectDetail({ projectId }) {
  try {
    const { getCollectionItemFromFirestore } = getFirebaseBackend();
    const res = yield call(
      getCollectionItemFromFirestore,
      "projects",
      projectId
    );

    yield put(getProjectDetailSuccess(res));
  } catch (error) {
    yield put(getProjectDetailFail(error));
  }
}

function* onUpdateProject({ payload: project }) {
  try {
    const response = yield call(updateProject, project);
    yield put(updateProjectSuccess(response));
  } catch (error) {
    yield put(updateProjectFail(error));
  }
}

function* onDeleteProject({ payload: project }) {
  try {
    const response = yield call(deleteProject, project);
    yield put(deleteProjectSuccess(response));
  } catch (error) {
    yield put(deleteProjectFail(error));
  }
}

function* onAddNewProject({ payload: project }) {
  try {
    const response = yield call(addNewProject, project);
    yield put(addProjectSuccess(response));
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* onAddComment({ payload: { project, comment } }) {
  try {
    yield updateDoc(doc(getFirestore(), "projects", `${project.id}`), {
      comments: arrayUnion(comment),
    });
    yield put(addCommentSuccess(comment, project));
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* onRemoveComment({ payload: { project, comment } }) {
  try {
    yield updateDoc(doc(getFirestore(), "projects", `${project.id}`), {
      comments: arrayRemove(comment),
    });
    yield put(removeCommentSuccess(comment, project));
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* onSaveTeamMembers({ payload: { project, teamMembers } }) {
  try {
    yield updateDoc(doc(getFirestore(), "projects", `${project.id}`), {
      team: teamMembers,
    });
    yield put(saveTeamMembersSuccess(project, teamMembers));
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* projectsSaga() {
  yield takeEvery(GET_PROJECTS, fetchProjects);
  yield takeEvery(GET_PROJECT_DETAIL, fetchProjectDetail);
  yield takeEvery(ADD_NEW_PROJECT, onAddNewProject);
  yield takeEvery(UPDATE_PROJECT, onUpdateProject);
  yield takeEvery(DELETE_PROJECT, onDeleteProject);
  yield takeEvery(ADD_COMMENT, onAddComment);
  yield takeEvery(REMOVE_COMMENT, onRemoveComment);
  yield takeEvery(SAVE_TEAM_MEMBERS, onSaveTeamMembers);
}

export default projectsSaga;
