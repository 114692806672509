import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, get } from "lodash";
import { Card, CardBody, Col, Row } from "reactstrap";
import img1 from "../../../assets/images/companies/img-1.png";
import moment from "moment";

const ProjectDetail = ({ project }) => {
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <img
            src={project?.files?.find(i => i.main)?.url || img1}
            alt=""
            className="avatar-sm me-4"
          />

          <div className="flex-grow-1 overflow-hidden w-75">
            <h5 className="text-truncate font-size-15">{project.name}</h5>
            <p className="text-muted">
              Založený {moment(project.id).format("DD.MM.YYYY")}
            </p>
          </div>
          {/* <div className="d-flex flex-column flex-grow-1 overflow-hidden">
            <button className="btn btn-outline-primary btn-sm mb-1 ">
              Upraviť
            </button>
            <button className="btn btn-outline-danger btn-sm">Vymazať</button>
          </div> */}
        </div>

        <h5 className="font-size-15 mt-4">Detaily:</h5>

        <p
          className="text-muted"
          dangerouslySetInnerHTML={{ __html: project?.descHTML }}
        ></p>

        <div className="text-muted mt-4">{project["additional-info"]}</div>

        <Row className="task-dates">
          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar me-1 text-primary" />
                Začiatok projektu
              </h5>
              <p className="text-muted mb-0">
                {moment(project.fromDate).isValid()
                  ? moment(project.fromDate).format("DD.MM.YYYY")
                  : "Nie je definovaný"}
              </p>
            </div>
          </Col>

          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar-check me-1 text-primary" />
                Deadline
              </h5>
              <p className="text-muted mb-0">
                {moment(project.dueDate).isValid()
                  ? moment(project.dueDate).format("DD.MM.YYYY")
                  : "Nie je definovaný"}
              </p>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

ProjectDetail.propTypes = {
  project: PropTypes.object,
};

export default ProjectDetail;
