import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { selectUserData } from "store/selectors";

const WelcomeComp = () => {
  const currentUser = useSelector(selectUserData);
  return (
    <Card className="overflow-hidden">
      <div className="bg-primary bg-soft">
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h5 className="text-primary">Vitajte späť!</h5>
              <p>v BeCode admin</p>
            </div>
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <Row>
          <Col sm="4">
            <div className="avatar-md profile-user-wid mb-4">
              <img
                src={currentUser?.profileImage}
                alt=""
                className="img-thumbnail rounded-circle"
              />
            </div>
            <h5 className="font-size-15 text-truncate">
              {currentUser?.firstName + " " + currentUser?.lastName}
            </h5>
            <p className="text-muted mb-0 text-truncate">{currentUser?.role}</p>
          </Col>

          {/* <Col sm="8">
            <div className="pt-4">
              <Row>
                <Col xs="6">
                  <h5 className="font-size-15">125</h5>
                  <p className="text-muted mb-0">Projects</p>
                </Col>
                <Col xs="6">
                  <h5 className="font-size-15">$1245</h5>
                  <p className="text-muted mb-0">Revenue</p>
                </Col>
              </Row>
              <div className="mt-4">
                <Link to="" className="btn btn-primary btn-sm">
                  View Profile <i className="mdi mdi-arrow-right ms-1" />
                </Link>
              </div>
            </div>
          </Col> */}
        </Row>
      </CardBody>
    </Card>
  );
};

export default WelcomeComp;
