import { doc, getDoc, getFirestore } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Media } from "reactstrap";

export const Client = ({ client }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentClient, setCurrentClient] = useState({
    firstName: "",
    lastName: "",
    profileImage: "",
    personalDetail: "",
    uid: "",
  });

  const getUser = async () => {
    await getDoc(doc(getFirestore(), "users", client.uid)).then(data =>
      setCurrentClient(data.data())
    );
    setIsLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Media className="mb-4">
      <div className="me-3">
        <div className="avatar-xs">
          <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-16">
            {currentClient.firstName.charAt(0)}
          </span>
        </div>
      </div>
      <Media body>
        <Link to={`/profile/${currentClient.uid}`}>
          <h5 className="font-size-13 mb-1">
            {currentClient.firstName + " " + currentClient.lastName}
          </h5>
        </Link>
        <p className="text-muted mb-1">{currentClient.email}</p>
        <p className="text-muted mb-1">{currentClient.phone}</p>
      </Media>
      {/* <div className="ms-3">
      <Link to="" className="text-primary">
        Chat
      </Link>
    </div> */}
    </Media>
  );
};
