import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Media } from "reactstrap";
import { Client } from "./Client";

const ContactDetails = ({ project }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">
          {project.client.length > 1 ? "Klienti" : "Klient"}
        </CardTitle>
        {project.client.map((client, l) => (
          <Client client={client} key={l} />
        ))}
      </CardBody>
    </Card>
  );
};

ContactDetails.propTypes = {
  comments: PropTypes.array,
};

export default ContactDetails;
