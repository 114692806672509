import skLocale from "@fullcalendar/core/locales/sk";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BootstrapTheme from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import "@fullcalendar/bootstrap/main.css";
import DeleteModal from "components/Common/DeleteModal";
import { getFirebaseBackend } from "helpers/firebase_helper";

const DayCalendar = () => {
  const [modal, setModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [event, setEvent] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const calendarComponentRef = useRef();
  const [events, setEvents] = useState([]);
  const { getUserFromFirestore } = getFirebaseBackend();

  const loadEvents = async () => {
    const eve = await getUserFromFirestore("events");
    setEvents([
      ...(eve || []),
      {
        id: 1,
        title: "Hey!",
        start: 1661011999999,
        className: "bg-warning text-white",
      },
    ]);
  };

  useEffect(() => {
    loadEvents();
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const handleDateClick = arg => {
    const date = arg["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );
    const modifiedData = { ...(arg || []), date: modifiedDate };
    setSelectedDay(modifiedData);

    toggle();
  };

  const handleEventClick = ({ event: ev }) => {
    setEvent({
      id: ev.id,
      title: ev.title,
      title_category: ev.title_category,
      start: ev.start,
      className: ev.classNames,
      category: ev.classNames[0],
      event_category: ev.classNames[0],
    });
    setIsEdit(true);
    toggle();
  };

  const handleDeleteEvent = () => {
    setDeleteModal(false);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row>
                <Col className="col-12">
                  <FullCalendar
                    locale={skLocale}
                    initialView="dayGridDay"
                    ref={calendarComponentRef}
                    plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                    slotDuration={"00:15:00"}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    headerToolbar={{
                      left: "title",
                      center: "",
                      right: "prev,next today",
                    }}
                    buttonText={{ today: "DNES" }}
                    events={events}
                    contentHeight="135px"
                    editable={true}
                    droppable={true}
                    selectable={true}
                    dateClick={handleDateClick}
                    eventClick={handleEventClick}
                  />

                  <Modal isOpen={modal} id="event-modal">
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Event" : "Add Event"}
                    </ModalHeader>
                    <ModalBody>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          title: (event && event.title) || "",
                          category: (event && event.category) || "Danger",
                        }}
                        validationSchema={Yup.object().shape({
                          title: Yup.string().required(
                            "Please Enter Your Event Name"
                          ),
                          category: Yup.string().required(
                            "Please Enter Your category"
                          ),
                        })}
                        onSubmit={values => {
                          if (isEdit) {
                            const updateEvent = {
                              id: event.id,
                              title: values.title,
                              classNames: values.category + " text-white",
                              start: event.start,
                            };
                            // update event
                            onUpdateEvent(updateEvent);
                          } else {
                            const newEvent = {
                              id: Math.floor(Math.random() * 100),
                              title: values["title"],
                              start: selectedDay
                                ? selectedDay.date
                                : new Date(),
                              className: values.category + " text-white",
                            };
                            // save new event
                            onAddNewEvent(newEvent);
                          }
                          setSelectedDay(null);
                          toggle();
                        }}
                      >
                        {({ errors, touched }) => (
                          <Form>
                            <Row>
                              <Col xs={12}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Event Name
                                  </Label>
                                  <Field
                                    name="title"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.title && touched.title
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="title"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col xs={12}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Select Category
                                  </Label>
                                  <Field
                                    name="category"
                                    as="select"
                                    className={
                                      "form-control" +
                                      (errors.category && touched.category
                                        ? " is-invalid"
                                        : "")
                                    }
                                  >
                                    <option value="bg-danger">Danger</option>
                                    <option value="bg-success">Success</option>
                                    <option value="bg-primary">Primary</option>
                                    <option value="bg-info">Info</option>
                                    <option value="bg-dark">Dark</option>
                                    <option value="bg-warning">Warning</option>
                                  </Field>
                                  <ErrorMessage
                                    name="category"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="button"
                                    className="btn btn-light me-2"
                                    onClick={toggle}
                                  >
                                    Zavrieť
                                  </button>
                                  {!!isEdit && (
                                    <button
                                      type="button"
                                      className="btn btn-danger me-2"
                                      onClick={() => setDeleteModal(true)}
                                    >
                                      Vymazať
                                    </button>
                                  )}
                                  <button
                                    type="submit"
                                    className="btn btn-success save-event"
                                  >
                                    Uložiť
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default connect()(DayCalendar);
