import {
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  ADD_COMMENT_SUCCESS,
  REMOVE_COMMENT_SUCCESS,
  UPDATE_FILES,
  SAVE_TEAM_MEMBERS_SUCCESS,
  CLEAR_PROJECTS,
} from "./actionTypes";

const INIT_STATE = {
  projects: {},
};

const projects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload.projects,
      };

    case GET_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        projects: { ...state.projects, [action.payload.id]: action.payload },
      };

    case GET_PROJECT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_COMMENT_SUCCESS:
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.payload.project.id]: {
            ...action.payload.project,
            comments: [
              ...state.projects[action.payload.project.id].comments,
              action.payload.comment,
            ],
          },
        },
      };

    case SAVE_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.payload.project.id]: {
            ...state.projects[action.payload.project.id],
            teamMembers: action.payload.teamMembers,
          },
        },
      };

    case REMOVE_COMMENT_SUCCESS:
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.payload.project.id]: {
            ...action.payload.project,
            comments: state.projects[action.payload.project.id].comments.filter(
              curr => curr.timestamp !== action.payload.comment.timestamp
            ),
          },
        },
      };

    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projects: { ...state.projects, [action.payload.id]: action.payload },
      };

    case UPDATE_FILES:
      return {
        ...state,
        projects: {
          [action.payload.project.id]: {
            ...state.projects[action.payload.project.id],
            files: [
              ...state.projects[action.payload.project.id].files,
              ...action.payload.files,
            ],
          },
        },
      };
    case ADD_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // case UPDATE_PROJECT_SUCCESS:
    //   return {
    //     ...state,
    //     projects: state.projects.map(project =>
    //       project.id.toString() === action.payload.id.toString()
    //         ? { project, ...action.payload }
    //         : project
    //     ),
    //   };

    // case UPDATE_PROJECT_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    // case DELETE_PROJECT_SUCCESS:
    //   return {
    //     ...state,
    //     projects: state.projects.filter(
    //       project => project.id.toString() !== action.payload.id.toString()
    //     ),
    //   };

    // case DELETE_PROJECT_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    case CLEAR_PROJECTS:
      return { ...state, projects: {} };

    default:
      return state;
  }
};

export default projects;
