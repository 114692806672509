import { Button } from "reactstrap";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

function onlyCapitalLetters(str) {
  let newStr = "";

  for (let i = 0; i < str.length; i++) {
    if (str[i].match(/[A-Z]/)) {
      newStr += str[i];
    }
  }
  return newStr;
}

export const projectColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
  },
  {
    dataField: "name",
    text: "Názov",
    sort: true,
  },
  {
    dataField: "fromDate",
    text: "Začiatok",
    sort: true,
    formatter: i =>
      moment(i).isValid() ? moment(i).format("DD.MM.YYYY") : "Neurčený",
  },
  {
    dataField: "dueDate",
    text: "Deadline",
    sort: true,
    formatter: i =>
      moment(i).isValid() ? moment(i).format("DD.MM.YYYY") : "Neurčený",
  },
  {
    dataField: "team",
    text: "Team",
    sort: false,
    formatter: i => {
      return i
        .map(item => item.label)
        .reduce((a, b) => [...a, onlyCapitalLetters(b)], [])
        .join(", ");
    },
  },
  {
    dataField: "client",
    text: "Klient",
    sort: false,
    formatter: i => {
      return i
        .map(item => item.label)
        .reduce((a, b) => [...a, onlyCapitalLetters(b)], [])
        .join(", ");
    },
  },
  {
    dataField: "id",
    text: "",
    sort: false,
    formatter: id => (
      <Link
        to={`/project-detail/${id}`}
        type="button"
        color="primary"
        className="btn-sm btn-rounded"
      >
        Zobraziť
      </Link>
    ),
  },
];
