/* PROJECTS */
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL";

/* PROJECTS DETAIL*/
export const GET_PROJECT_DETAIL = "GET_PROJECT_DETAIL";
export const GET_PROJECT_DETAIL_SUCCESS = "GET_PROJECT_DETAIL_SUCCESS";
export const GET_PROJECT_DETAIL_FAIL = "GET_PROJECT_DETAIL_FAIL";

export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL";

export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL";

export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL";

export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const REMOVE_COMMENT_SUCCESS = "REMOVE_COMMENT_SUCCESS";

export const UPDATE_FILES = "UPDATE_FILES";

export const SAVE_TEAM_MEMBERS = "SAVE_TEAM_MEMBERS";
export const SAVE_TEAM_MEMBERS_SUCCESS = "SAVE_TEAM_MEMBERS_SUCCESS";

export const CLEAR_PROJECTS = "CLEAR_PROJECTS";
