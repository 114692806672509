import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { projectColumns } from "./constants";
const MyProjects = ({ projects }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4 h4">Moje projekty</CardTitle>
        <ToolkitProvider
          keyField="id"
          data={Object.values(projects) || []}
          columns={projectColumns}
          bootstrap4
        >
          {toolkitProps => (
            <React.Fragment>
              <Row>
                <Col xl="12">
                  <div className="table-responsive">
                    <BootstrapTable
                      responsive
                      remote
                      bordered={false}
                      striped={false}
                      classes={"table table-nowrap table-hover mb-0"}
                      headerWrapperClasses={"tbody-light"}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  );
};

export default MyProjects;
